.card-container-tareas-notas {
    display: flex;
    flex-wrap: wrap;    
    gap: 20px;
    /* Espacio entre tarjetas */
    text-overflow: ellipsis;
}

@media (max-width: 600px) {
    .card-tarea-nota {
        margin-left: 20%;

    }
  }

.card-tarea-nota {
    width: 200px;
    /* Ancho fijo */
    height: 200px;
    /* Alto fijo */
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Espacio entre los elementos */
    text-align: center;
    cursor: pointer;
}

.card-tarea-nota h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.card-tarea-nota p {
    flex-grow: 2;
    /* Hace que la descripción ocupe el espacio disponible */
    margin-bottom: 10px;

}

.card-tarea-nota .estado {
    align-self: center;
    /* Centra el interruptor horizontalmente */
}

.divider {
    border: 0;
    height: 0.5px;
    background: #ccc;
    margin: 10px 0;
  margin-top: -10px;
}