@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-Bold.woff2') format('woff2'),
        url('./Fuentes/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-BoldItalic.woff2') format('woff2'),
        url('./Fuentes/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('./Fuentes/OpenSans-CondensedBold.woff2') format('woff2'),
        url('./Fuentes/OpenSans-CondensedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('./Fuentes/OpenSans-CondensedLight.woff2') format('woff2'),
        url('./Fuentes/OpenSans-CondensedLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('./Fuentes/OpenSans-CondensedLightItalic.woff2') format('woff2'),
        url('./Fuentes/OpenSans-CondensedLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-Extrabold.woff2') format('woff2'),
        url('./Fuentes/OpenSans-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-ExtraboldItalic.woff2') format('woff2'),
        url('./Fuentes/OpenSans-ExtraboldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-Italic.woff2') format('woff2'),
        url('./Fuentes/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-Light.woff2') format('woff2'),
        url('./Fuentes/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSansLight-Italic.woff2') format('woff2'),
        url('./Fuentes/OpenSansLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans.woff2') format('woff2'),
        url('./Fuentes/OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('./Fuentes/OpenSans-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./Fuentes/OpenSans-Semibold.woff2') format('woff2'),
        url('./Fuentes/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-ExtraBold.woff2') format('woff2'),
        url('./Fuentes/Heebo-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Bold.woff2') format('woff2'),
        url('./Fuentes/Heebo-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Black.woff2') format('woff2'),
        url('./Fuentes/Heebo-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Regular.woff2') format('woff2'),
        url('./Fuentes/Heebo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-SemiBold.woff2') format('woff2'),
        url('./Fuentes/Heebo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-ExtraLight.woff2') format('woff2'),
        url('./Fuentes/Heebo-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Medium.woff2') format('woff2'),
        url('./Fuentes/Heebo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Light.woff2') format('woff2'),
        url('./Fuentes/Heebo-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('./Fuentes/Heebo-Thin.woff2') format('woff2'),
        url('./Fuentes/Heebo-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

body {
  margin: 0;
  font-family: 'Open Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
