 @media only screen and (min-width: 1280px) {
 
    .CatalogoArticulosRow{
        margin-left: 28%;
    }
 }

 @media only screen and (min-width: 2300px) {

    .CatalogoArticulosRow{
        margin-left: 25%;
    }
 }

 .collapse.show{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
 }
 .checkbox-wrapper-4 * {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}
  
.collapse.show {
    display: flex !important;
    justify-content: center !important;
}

