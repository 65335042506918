/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 425) {}

@media only screen and (max-width: 500px) {
  .icons-cards {
    margin-left: 270px !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .icons-cards {
    margin-left: 300px !important;
  }

  .container-cards {
    margin-left: 48px !important;
  }
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .icons-cards {
    margin-left: 300px !important;
  }

  .container-cards {
    margin-left: 48px !important;
  }

}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .modal-upload {
    width: 430px !important;
    /* max-width: 90vw; */
  }

  .container-cards {
    margin-left: 18px !important;
  }

  .icons-cards {
    margin-left: 290px !important;
  }

  .iconCheckModal {}
}

.icons-cards {
  display: 'flex';
}

/* From uiverse.io by @satyamchaudharydev */
/* removing default style of button */

.form-search button {
  border: none;
  background: none;
  color: #8b8ba7;
}

/* styling of whole input container */
.form-search {
  --timing: 0.3s;
  --width-of-input: 200px;
  --height-of-input: 40px;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: #2f2ee9;
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  width: var(--width-of-input);
  height: var(--height-of-input);
  display: flex;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: var(--border-radius);
  transition: border-radius 0.5s ease;
  background: var(--input-bg, #fff);
}

/* styling of Input */
.input-search {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
}

/* styling of animated border */
.form-search:before {
  content: "";
  position: absolute;
  background: var(--border-color);
  transform: scaleX(0);
  transform-origin: center;
  width: 100%;
  height: var(--border-height);
  left: 0;
  bottom: 0;
  border-radius: 1px;
  transition: transform var(--timing) ease;
}

/* Hover on Input */
.form-search:focus-within {
  border-radius: var(--after-border-radius);
}

input:focus {
  outline: none;
}

/* here is code of animated border */
.form-search:focus-within:before {
  transform: scale(1);
}

/* styling of close button */
/* == you can click the close button to remove text == */
/* close button shown when typing */
input:not(:placeholder-shown)~.reset {
  opacity: 1;
  visibility: visible;
}

/* sizing svg icons */
.form-search svg {
  width: 17px;
  margin-top: 3px;
}


.styleLink{
  background: none;
  color: #0151ff;
  border: none;
  padding: 0;
  font: inherit;                                                                             
  cursor: pointer;
}

.styleLink:focus {
  text-decoration: underline;
}